<template>
    <div class="report-mini__form__unp report-mini__form__item">
        <multiselect
            v-model="selectedOptions"
            :options="options"
            :multiple="false"
            label="full"
            :clear-on-select="true"
            :remote-search-delay="500"
            track-by="unp"
            :internal-search="false"
            class="report-mini__form__unp__input report-mini__input"
            placeholder="Укажите УНП либо наименование организации"
            @search-change="fetchOptions"
        >
            <template v-slot:singleLabel="{ option }">{{ option.unp }}</template>
        </multiselect>
        <input type="hidden" name="unp" :value="selectedOptions.unp">
    </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect,
    },
    setup() {
        const selectedOptions = ref([]);
        const options = ref([]);
        const loading = ref(false);

        const fetchOptions = (searchQuery) => {
            loading.value = true;
            axios.get(`/report/search?query=${searchQuery}`)
                .then(response => {
                    options.value = response.data;
                    loading.value = false;
                })
                .catch(error => {
                    console.error(error);
                    loading.value = false;
                });
        };

        return {
            selectedOptions,
            options,
            loading,
            fetchOptions,
        };
    }
};
</script>

<template>
    <div>
        <input v-model="text" @input="updateValue" />
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: {
        rowId: {
            type: Number,
            required: true
        },
        columnId: {
            type: Number,
            required: true
        },
        tableId: {
            type: Number,
            required: true
        },
        initText: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            text: this.initText
        }
    },
    methods: {
        async updateValue() {
            axios
                .put(`/admin/report-table/${this.tableId}/${this.columnId}/${this.rowId}`, {'text':this.text})
                .then((response) => {

                });
        }
    }
}
</script>

<template>
    <div>
        <input type="text" :value=vValue @change="save(item,$event)">
    </div>
</template>

<script>
export default {
    props: [
        'profitability_id',
        'year',
        'value',
    ],
    data() {
        return {
            vYear: this.year,
            vValue: this.value,
            vProfitability_id: this.profitability_id,
        }
    },
    methods: {
        save(item,$event){
            axios.put(`/admin/profitability/${this.vProfitability_id}`,
                {
                    value: event.target.value,
                    year: this.vYear,
                })
                .then(response => {
                    console.log('Элемент успешно обновлен');
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}
</script>

<template>
    <div class="report-mini__form__economic-activity__wrapper">
        <multiselect
            v-model="selectedOptions"
            :options="options"
            :multiple="false"
            rack-by="title"
            label="title"
            :showNoOptions="false"
            placeholder="Введите значение"
            :internal-search="false"
            @search-change="fetchOptions"
            @update:modelValue="sendDataAndEmitEvent"
        >
        </multiselect>
    </div>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect,
    },
    props: [
        'labelText',
    ],
    data() {
        return {
            options: [],
            loading: false,
            selectedOptions: this.parseLabelText(this.labelText),
        };
    },
    methods: {
        parseLabelText(labelText) {
            return {
                title: labelText,
            };
        },
        fetchOptions(searchQuery) {
            this.loading = true;
            axios.get(`/api/activity?query=${searchQuery}`)
                .then(response => {
                    this.options = response.data;
                    this.loading = false;

                    const match = response.data.find(option => option.id === this.selectedOptions.id);
                    if (!match) {
                        this.options.unshift(this.selectedOptions);
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.loading = false;
                });
        },
        sendDataAndEmitEvent() {
            this.sendData();
            this.$emit('activity', this.selectedOptions.title);
        },
        sendData() {
            const data = {
                activity: this.selectedOptions.title,
            };
            axios.put('/api/user', data)
                .then(response => {
                    // Обработка ответа, если необходимо
                })
                .catch(error => {
                    console.error(error);
                });
        },
    },
    mounted() {
        this.fetchOptions('');
        this.$emit('activity', this.labelText);
    },
};
</script>

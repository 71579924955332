window.sendRequest = () => {

    document.getElementById('bad_itog').style.display = 'none'
    var xhr = new XMLHttpRequest();

    xhr.open('GET', '/init/grade', true);
    xhr.onreadystatechange = function() {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
                getTables();
            } else if(xhr.status === 400){
                document.getElementById('bad_itog').style.display = 'block';
                document.getElementById('loader').style.display  = 'none';
            } else {
                sendRequest(); // Отправляем запрос заново, если ответ не 200
            }
        }
    };

    xhr.send();
}
window.sendRequestFile = () => {

    var xhr = new XMLHttpRequest();

    xhr.open('GET', '/export', true);
    xhr.onreadystatechange = function() {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
                document.getElementById('loader').style.display  = 'none'
                window.location.href = '/export';
            } else {
                sendRequest(); // Отправляем запрос заново, если ответ не 200
            }
        }
    };

    xhr.send();
}

window.removeColumn = () => {
    var xhr = new XMLHttpRequest();

    xhr.open('GET', '/api/column/remove', true);
    xhr.onreadystatechange = function() {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
                document.getElementById('loader').style.display  = 'none'
                window.location.hash = '#buh';
                window.location.reload();
            }
        }
    };

    xhr.send();
}

window.removeFirstColumn = () => {
    var xhr = new XMLHttpRequest();

    xhr.open('GET', '/api/column/removeFirst', true);
    xhr.onreadystatechange = function() {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
                document.getElementById('loader').style.display  = 'none'
                window.location.hash = '#buh';
                window.location.reload();
            }
        }
    };

    xhr.send();
}

window.addColumn = () => {
    var xhr = new XMLHttpRequest();

    xhr.open('GET', '/api/column/add', true);
    xhr.onreadystatechange = function() {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
                document.getElementById('loader').style.display  = 'none'
                window.location.hash = '#buh';
                window.location.reload();
            }
        }
    };

    xhr.send();
}

window.getTables = async () => {
    const xhr = new XMLHttpRequest();
    let arr = [];
    let index = 1;
    xhr.open('GET', '/init/grade', true);

    xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
                arr = JSON.parse(xhr.response);
                const promises = arr.map((element) => getTable(element, index));

                Promise.all(promises).then(() => {
                    // Все запросы завершены успешно
                    window.location.href = '/grade';
                });
            }
        }
    };

    xhr.send();
}

window.getTable = async (id, index) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', '/init/table?table_id=' + id + "&index=" + index, true);

        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                } else {
                    // Запрос не удался, повторяем попытку через 1 секунду
                    setTimeout(() => {
                        resolve(getTable(id, index));
                    }, 1000);
                }
            }
        };

        xhr.send();
    });
}

/*window.getTables = () => {
    var xhr = new XMLHttpRequest();
    let arr = [];
    let index = 1;
    xhr.open('GET', '/init/grade', true);
    xhr.onreadystatechange = function() {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
                arr = JSON.parse(xhr.response);
                //window.location.href = '/grade';
                arr.forEach((element) => {
                    getTable(element, index)
                    index++;
                });
                document.getElementById('section-loader').style.display  = 'none'
            }
        }
    };

    xhr.send();
}*/

/*window.getTables = async () => {
    var xhr = new XMLHttpRequest();
    let arr = [];
    let index = 1;
    xhr.open('GET', '/init/grade', true);
    xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
                arr = JSON.parse(xhr.response);
                //window.location.href = '/grade';
                processTables(arr, index);
            }
        }
    };

    xhr.send();

    await new Promise(resolve => setTimeout(resolve, 1000));
}

async function processTables(arr, index) {
    for (let element of arr) {
        await getTable(element, index);
        index++;
    }
    document.getElementById('section-loader').style.display = 'none';
}

window.getTable = async (id, index) => {
    var xhr = new XMLHttpRequest();
    let arr = [];
    xhr.open('GET', '/init/table?table_id=' + id + "&index=" + index, true);
    await new Promise(resolve => {
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    document.getElementById('section-loader').insertAdjacentHTML('beforebegin', xhr.response)
                    resolve();
                } else {
                    setTimeout(resolve, 1000);
                }
            }
        };
        xhr.send();
    });
}*/


document.addEventListener("DOMContentLoaded", function() {
    var body = document.getElementsByTagName('body')[0];
    var script = document.createElement('script');
    script.async = true;
    script.src = '//cdn.pulse.is/livechat/loader.js';
    script.setAttribute('data-live-chat-id', '65ec2455388498e01b09faf6');
    body.appendChild(script);
});

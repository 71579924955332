<!-- ParentComponent.vue -->
<template>
    <div
        class="report-mini__form__name-of-the-organization report-mini__form__item"
    >
        <div
            class="report-mini__form__name-of-the-organization__title report-mini__form__item__title"
        >
            Наименование организации
        </div>
        <input
            type="text"
            class="report-mini__form__name-of-the-organization__input report-mini__input"
            v-model="organization"
            @change="updateData"
        />
    </div>

    <div class="report-mini__form__units report-mini__form__item">
        <div
            class="report-mini__form__units__title report-mini__form__item__title"
        >
            Единица измерения
        </div>
        <div class="report-mini__form__units__wrapper">
            <select disabled
                class="report-mini__form__units__input report-mini__input"
                name="units-select"
                v-model="units"
                @change="updateData"
            >
                <option value="rub">рубль</option>
                <option value="thousands">тыс. руб.</option>
                <option value="millions">млн. руб</option>
            </select>

            <img
                src="/icons/arrow-down.svg"
                alt="arrow"
                class="report-mini__form__units__arrow"
            />
        </div>
    </div>
    <div class="report-mini__form__economic-activity report-mini__form__item">
        <div
            class="report-mini__form__economic-activity__title report-mini__form__item__title"
        >
            Вид экономической деятельности
        </div>
        <activity @activity="handleFirstInputSelected" :labelText="labelText" />

        <img
            src="icons/arrow-down.svg"
            alt="arrow"
            class="report-mini__form__economic-activity__arrow"
        />
    </div>

    <div class="report-mini__form__group report-mini__form__item">
        <div
            class="report-mini__form__group__title report-mini__form__item__title"
        >
            Номер группы
        </div>
        <group
            :isDisabled="isSecondMultiselectDisabled"
            :labelGroup="labelGroup"
            :activityValue="activitySelectedValue"
        />
        <img
            src="icons/arrow-down.svg"
            alt="arrow"
            class="report-mini__form__group__arrow"
        />
    </div>
    <div class="report-mini__form__reporting report-mini__form__item">
        <div
            class="report-mini__form__reporting__title report-mini__form__item__title"
        >
            Отчетность
        </div>
        <select
            class="report-mini__form__units__input report-mini__input"
            name="units-select"
            @change="goStep"
            v-model="selectedOption"
        >
            <option value="buh">Бухгалтерский баланс</option>
            <option value="report_cost">Отчет о прибылях и убытках</option>
            <option value="trafic">Отчет о движении денежных средств</option>
            <option value="report_add">Дополнительные данные</option>
        </select>

        <img
            src="/icons/arrow-down.svg"
            alt="arrow"
            class="report-mini__form__report__arrow"
        />
    </div>
</template>

<script>
import Activity from "./Activity.vue";
import Group from "./Group.vue";

export default {
    components: {
        Activity,
        Group,
    },
    props: [
        "labelText",
        "labelGroup",
        "initUnits",
        "initOrganization",
    ],
    data() {
        return {
            isSecondMultiselectDisabled: true,
            activitySelectedValue: null,
            organization: this.initOrganization,
            units: this.initUnits,
            economicActivity: "",
            group: "",
            reporting: "",
            selectedOption: "buh",
        };
    },
    methods: {
        handleFirstInputSelected(value) {
            this.activitySelectedValue = value;
            this.isSecondMultiselectDisabled = false;
        },
        getFormData() {
            return {
                organization: this.organization,
                units: this.units,
            };
        },
        updateData() {
            axios
                .put("/api/user", this.getFormData())
                .then((response) => {
                    //console.log("Данные успешно сохранены!");
                })
                .catch((error) => {
                    //console.log("Ошибка при сохранении данных: ", error);
                });
        },
        goStep() {
            if (this.selectedOption) {
                window.location.href = `#${this.selectedOption}`;
            }
        },
    },
    watch: {
        activitySelectedValue: {
            handler(newValue) {
                this.isSecondMultiselectDisabled = !newValue;
            },
            immediate: true,
        },
    },
};
</script>

<template>
    <div>
        <button @click="addBlock" class="btn btn-primary">Добавить блок</button>&nbsp;
        <button @click="saveBlocks" class="btn btn-primary">Сохранить изменения</button>
        <table class="table mb-0">
            <thead>
            <tr>
                <th>Название</th>
                <th></th>
            </tr>
            </thead>
            <draggable v-model="blocksRef" tag="tbody">
                <template v-slot:item="{ element: block, index }">
                    <tr>
                        <td><input v-model="block.title" class="form-control form-control-lg mt-15"/></td>
                        <td><input v-model="block.link" class="form-control form-control-lg mt-15"/></td>
                        <td><button @click="deleteBlock(index)" class="btn btn-danger">Удалить строку</button></td>
                    </tr>
                </template>
            </draggable>
        </table>
    </div>
</template>

<script>
import { ref, watch, reactive } from "vue";
import draggable from "vuedraggable";
import axios from "axios";

export default {
    components: {
        draggable,
    },
    props: [
        "initialBlocks",
        "table_id",
    ],
    setup(props) {
        const blocksRef = ref(props.initialBlocks);

        watch(blocksRef, () => {
            // Выполнять действия при отслеживании изменений массива блоков
        });

        const addBlock = () => {
            const newBlock = {
                id: null,
                title: "",
            };
            blocksRef.value.push(newBlock);
        };

        const deleteBlock = (index) => {
            blocksRef.value.splice(index, 1);
        };

        const updateBlockId = (index, id) => {
            blocksRef.value[index].id = id;
        };

        const saveBlocks = () => {
            // Отправляем массив с изменениями на сервер
            axios
                .put(`/admin/report-table/${props.table_id}/row`, blocksRef.value)
                .then((response) => {
                    // Получаем сохраненные блоки из ответа сервера и обновляем ID блоков
                    const savedBlocks = response.data;
                    for (let i = 0; i < blocksRef.value.length; i++) {
                        if (blocksRef.value[i].id === null) {
                            updateBlockId(i, savedBlocks[i].id);
                        }
                    }
                });
        };

        return {
            blocksRef,
            addBlock,
            deleteBlock,
            saveBlocks,
        };
    },
};
</script>

<template>
    <div>
        <button @click="addBlock" class="btn btn-primary">Добавить условие</button>&nbsp;
        <button @click="saveBlocks" class="btn btn-primary">Сохранить изменения</button>
        <table class="table mb-0">
            <thead>
            <tr>
                <th>Условия</th>
                <th>Текст вывода</th>
                <th>Написание</th>
                <th>Знак вывода</th>
                <th></th>
            </tr>
            </thead>

            <draggable v-model="blocksRef" tag="tbody">
                <template v-slot:item="{ element: block, index }">
                    <tr>
                        <td>
                            <textarea style="min-width: 280px" v-model="block.condition"></textarea>
                        </td>
                        <td>
                            <textarea style="min-width: 280px" v-model="block.text"></textarea>
                        </td>
                        <td>
                            <select v-model="block.type">
                                <option value="inline">Продолжить</option>
                                <option value="new">Новый абзац</option>
                            </select>
                        </td>
                        <td>
                            <select v-model="block.grade">
                                <option value="">-</option>
                                <option value="like">like</option>
                                <option value="dislike">dislike</option>
                                <option value="warning">warning</option>
                            </select>
                        </td>
                        <td><button @click="deleteBlock(index)" class="btn btn-danger">Удалить Условие</button></td>
                    </tr>
                </template>
            </draggable>
        </table>
    </div>
</template>

<script>
import { ref, watch, reactive } from "vue";
import draggable from "vuedraggable";
import axios from "axios";

export default {
    components: {
        draggable,
    },
    props: [
        "initialBlocks",
        "section_id",
    ],
    setup(props) {
        const blocksRef = ref(props.initialBlocks);

        watch(blocksRef, () => {
            // Выполнять действия при отслеживании изменений массива блоков
        });

        const addBlock = () => {
            const newBlock = {
                id: null,
                condition: null,
                text: null,
                type: null,
                grade: null,
            };
            blocksRef.value.push(newBlock);
        };

        const deleteBlock = (index) => {
            blocksRef.value.splice(index, 1);
        };

        const updateBlockId = (index, id) => {
            blocksRef.value[index].id = id;
        };

        const saveBlocks = () => {
            // Отправляем массив с изменениями на сервер
            axios
                .put(`/admin/section/${props.section_id}/condition`, blocksRef.value)
                .then((response) => {
                    // Получаем сохраненные блоки из ответа сервера и обновляем ID блоков
                    const savedBlocks = response.data;
                    for (let i = 0; i < blocksRef.value.length; i++) {
                        if (blocksRef.value[i].id === null) {
                            updateBlockId(i, savedBlocks[i].id);
                        }
                    }
                });
        };

        return {
            blocksRef,
            addBlock,
            deleteBlock,
            saveBlocks,
        };
    },
};
</script>

<template>
    <div>
        <input type="text" :value=vValue class="report__table__item__input report-table-item__input" @change="save(item,$event)">
    </div>
</template>

<script>
export default {
    props: [
        'field',
        'report_id',
        'value',
    ],
    data() {
        return {
            vField: this.field,
            vValue: this.value,
            vReport_id: this.report_id,
        }
    },
    methods: {
        save(item,$event){
            axios.put(`/report/${this.vReport_id}`,
                {
                    value: event.target.value,
                    field: this.vField,
                })
                .then(response => {
                    console.log('Элемент успешно обновлен');
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}
</script>
